<template>
  <div class="point_button_container">
    <button @click="pushReturn" class="top_ret_button">戻る</button>
    <div v-if="!is_show_pointform">
      <button class="point_top_button" @click="handleShowPointForm">
        ポイント付与
      </button>
    </div>
    <div v-if="!is_show_pointuseform">
      <button class="point_top_button" @click="handleShowUsePointForm" disabled=true>ポイント利用登録</button>
    </div>
  </div>

  <div v-if="applicateduseDatas.length > 0">
    <UsePointApplication :use_datas="applicateduseDatas" :user_id="engineer_id" />
  </div>

  <div v-if="is_show_pointform">
    <UserPointAddForm :user_data="defaultdata" @point_add_finish="handleAddUserPoint"
      @close_add_point="handleAddPointClose" />
  </div>

  <div v-if="is_show_pointuseform">
    <UserPointUseForm :user_data="defaultdata" @point_use_finish="handleAddUserPoint"
      @handle_use_close="handleUsePointClose" />
  </div>

  <div class="detail_engineer_box">
    <div v-if="data_error">{{ data_error }}</div>

    <h3>社内貢献ポイントデータ</h3>
    <div v-if="defaultdata">
      <div class="point_detail">
        <div class="entry_item">
          <h5>エンジニア氏名</h5>
          <div v-if="defaultdata">
            {{ defaultdata.last_name }}{{ defaultdata.first_name }}
          </div>
        </div>

        <div class="entry_item">
          <h5>保有ポイント</h5>
          <div v-if="defaultdata">{{ totalPoint }}pt</div>
        </div>

        <div class="entry_item">
          <h5>換金可能ポイント</h5>
          <div v-if="defaultdata">{{ canChangePoint }}pt</div>
        </div>

        <div class="entry_item">
          <h5>換金可能額</h5>
          <div v-if="defaultdata">{{ changeMoney }}円</div>
        </div>

        <div class="entry_item">
          <h5>最終ポイント取得日</h5>
          <div v-if="latest_date">
            <div v-if="typeof latest_date === 'string'">
              {{ latest_date }}
            </div>

            <div v-else>
              {{ latest_date.toDate().toLocaleDateString("sv-SE") }}
            </div>
          </div>
          <div v-else>データなし</div>
        </div>
      </div>

      <div class="point_detail_data">
        <div class="point_title">ポイント明細</div>

        <div v-if="sortedPointDatas.length">
          <table>
            <th>
              <div class="point_table_No">No.</div>
            </th>
            <th>
              <div class="point_table_title">付与/使用</div>
            </th>
            <th>
              <div class="point_table_item_reason">理由</div>
            </th>
            <th>
              <div class="point_table_title">ポイント</div>
            </th>
            <th>
              <div class="point_table_title">備考</div>
            </th>
            <th>
              <div class="point_table_title">日付</div>
            </th>
            <th>
              <div class="point_table_title">登録日</div>
            </th>

            <tr v-for="(filteredpoint, index) in sortedPointDatas" :key="filteredpoint.id">
              <td>{{ (index + 1).toString().padStart(2, "0") }}</td>
              <td>
                <div class="table_item">
                  <div v-if="filteredpoint.point_status == 'add'" class="paid_text">
                    付与
                  </div>
                  <div v-if="filteredpoint.point_status == 'use'" class="absent_text">
                    使用
                  </div>
                </div>
              </td>
              <td>
                <div class="table_item">{{ filteredpoint.point_content }}</div>
              </td>
              <td>
                <div class="table_item">{{ filteredpoint.points }}pt</div>
              </td>
              <td>
                <div class="table_item">{{ filteredpoint.point_other }}</div>
              </td>
              <td>
                <div class="table_item">
                  {{ filteredpoint.formatted_point_date }}
                </div>

              </td>
              <td>
                <div class="table_item">
                  {{ filteredpoint.createdAt.toDate().getFullYear() }}-{{
                    filteredpoint.createdAt.toDate().getMonth() + 1
                  }}-{{ filteredpoint.createdAt.toDate().getDate() }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>データがありません。</div>
      </div>
    </div>
    <div v-if="!defaultdata" class="pulse-box">
      <Pulse />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import getDefaultData from "../composables/getDefaultData";
import getDefaultDatas from "../composables/getDefaultDatas";
import getChangeMoney from "../composables/getChangeMoney";
import Pulse from "../Pulse.vue";
import { projectFirestore } from "@/firebase/config";
import UserPointAddForm from "./UserPointAddForm.vue";
import UsePointApplication from "./UsePointApplication.vue";
import UserPointUseForm from "./UserPointUseForm.vue";


export default {
  props: ["engineer_id"],
  name: "DetailSelectedEngineer",
  components: {
    Pulse,
    UserPointAddForm,
    UsePointApplication,
    UserPointUseForm,
  },
  data() {
    return {};
  },
  computed: {
    fomattedDate() { },
  },

  setup(props, context) {
    const filteredpointDatas = ref([]);
    const filteredaddDatas = ref([]);
    const filtereduseDatas = ref([]);
    const applicateduseDatas = ref([]);
    const totalUsePoint = ref(0);
    const totaladdPoint = ref(0);
    const totalPoint = ref(0);
    const changeMoney = ref(0);
    const canChangePoint = ref(0);
    const latest_date = ref("");
    const is_show_pointform = ref(false);
    const is_show_pointuseform = ref(false);
    const point_button_title = ref("ポイント付与");

    const sortedPointDatas = ref([]);

    const { defaultdata, data_error, data_load } = getDefaultData(
      "pointData",
      props.engineer_id
    );
    data_load();

    const {
      defaultdatas: pointdatas,
      datas_error,
      datas_load,
    } = getDefaultDatas("points");
    datas_load().then(() => {
      filteredpointDatas.value = pointdatas.value.filter((item) => {
        return props.engineer_id === item.point_user_id;
      });


      watch(pointdatas, (newPointDatas) => {
        filteredpointDatas.value = newPointDatas.filter((item) => {
          return props.engineer_id === item.point_user_id;
        });

        console.log("length", filteredpointDatas.value.length)
        filteredpointDatas.value.sort((a, b) => b.point_date - a.point_date);

        if (
          filteredpointDatas.value[filteredpointDatas.value.length - 1] &&
          filteredpointDatas.value[filteredpointDatas.value.length - 1].point_date
        ) {
          latest_date.value = filteredpointDatas.value[filteredpointDatas.value.length - 1].point_date;
        } else {
          latest_date.value = null;
        }

        //ポイント付与分の計算
        filteredaddDatas.value = filteredpointDatas.value.filter((item) => {
          return item.point_status === "add";
        });

        totaladdPoint.value = filteredaddDatas.value.reduce(
          (total, point) => total + point.points,
          0
        );
        //ポイント使用分の計算
        filtereduseDatas.value = filteredpointDatas.value.filter((item) => {
          return item.point_status === "use";
        });

        totalUsePoint.value = filtereduseDatas.value.reduce(
          (total, point) => total + point.points,
          0
        );

        //申請データの出力
        applicateduseDatas.value = filteredpointDatas.value.filter((item) => {
          return item.point_status === "use" && item.is_submit === true;
        });



        totalPoint.value = totaladdPoint.value - totalUsePoint.value;


        const { changemoney, can_change_point } = getChangeMoney(
          totalPoint.value
        );
        canChangePoint.value = can_change_point.value;
        changeMoney.value = changemoney.value;


      });

      sortedPointDatas.value = filteredpointDatas.value
        .map((item) => {
          let date;
          if (item.point_date && typeof item.point_date.toDate === 'function') {
            date = item.point_date.toDate();
          } else {
            date = new Date(item.point_date)
          }

          const formattedDate = date.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).replace(/\//g, '-');

          return {
            ...item,
            point_date: date,
            formatted_point_date: formattedDate
          }

        }).sort((a, b) => (a.point_date.getTime() > b.point_date.getTime() ? 1 : -1))





      if (
        filteredpointDatas.value[filteredpointDatas.value.length - 1] &&
        filteredpointDatas.value[filteredpointDatas.value.length - 1].point_date
      ) {
        latest_date.value = filteredpointDatas.value[filteredpointDatas.value.length - 1].point_date;
      } else {
        latest_date.value = null;
      }
      //ポイント付与分の計算
      filteredaddDatas.value = filteredpointDatas.value.filter((item) => {
        return item.point_status === "add";
      });

      totaladdPoint.value = filteredaddDatas.value.reduce(
        (total, point) => total + point.points,
        0
      );
      //ポイント使用分の計算
      filtereduseDatas.value = filteredpointDatas.value.filter((item) => {
        return item.point_status === "use";
      });

      totalUsePoint.value = filtereduseDatas.value.reduce(
        (total, point) => total + point.points,
        0
      );

      //申請データの出力
      applicateduseDatas.value = filteredpointDatas.value.filter((item) => {
        return item.point_status === "use" && item.is_submit === true;
      });

      console.log("appdata", applicateduseDatas.value);

      totalPoint.value = totaladdPoint.value - totalUsePoint.value;

      console.log("totalPoint", totalPoint.value);
      const { changemoney, can_change_point } = getChangeMoney(
        totalPoint.value
      );
      canChangePoint.value = can_change_point.value;
      changeMoney.value = changemoney.value;
    });

    const handleAddUserPoint = async () => {
      try {
        await datas_load();
      } catch (err) {
        console.error("データ取得エラー", err);
      }
    };

    const pushReturn = async (emit) => {
      console.log("changeMoney:", changeMoney.value);
      const post = {
        hold_point: totalPoint.value,
        can_change_money: changeMoney.value,
        final_change: latest_date.value,
      };

      try {
        const res = await projectFirestore
          .collection("pointData")
          .doc(props.engineer_id)
          .update(post);
        console.log("ポイントデータを更新しました。");
        context.emit("return-button-click");
      } catch (err) {
        console.log(props.engineer_id);
        console.log("ポイントデータの更新に失敗しました:", err);
      }
    };

    const handleShowPointForm = () => {
      is_show_pointform.value = !is_show_pointform.value;
      if (!is_show_pointform.value) {
        point_button_title.value = "キャンセル";
      } else {
        point_button_title.value = "ポイント付与";
      }
    };


    const handleShowUsePointForm = () => {
      is_show_pointuseform.value = !is_show_pointuseform.value
    }

    const handleAddPointClose = () => {
      is_show_pointform.value = false;
    }

    const handleUsePointClose = () => {
      is_show_pointuseform.value = false;
    }



    return {
      defaultdata,
      data_load,
      data_error,
      pointdatas,
      filteredpointDatas,
      totalPoint,
      changeMoney,
      canChangePoint,
      latest_date,
      is_show_pointform,
      is_show_pointuseform,
      point_button_title,
      applicateduseDatas,
      handleAddUserPoint,
      handleShowPointForm,
      pushReturn,
      handleShowUsePointForm,
      handleAddPointClose,
      handleUsePointClose,
      sortedPointDatas
    };
  },
  methods: {},
  emits: ["return-button-click"],
};
</script>

<style>
.add {
  color: greenyellow;
}

.use {
  color: red;
}

.entry_part {
  margin-left: 62px;
  width: 400px;
}

.entry_part2 {
  margin-left: 62px;
}

.entry_item {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item p {
  font-size: 14px;
  margin: 0;
}

.entry_item input {
  width: 259px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.entry_item_name p {
  font-size: 14px;
  margin: 0;
}

.entry_item_name {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_name input {
  width: 159px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.entry_item_address {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}

.entry_item_address p {
  font-size: 14px;
  margin: 0;
}

.entry_item_address input {
  width: 550px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.emergency {
  display: flex;
}

.emergency .entry_item {
  width: 270px;
}

.face_up_box {
  width: 260px;
  height: 200px;
  background: #e3f5ff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #105ef5;
  font-size: 14px;
}

.add_engineer_box button {
  width: 241px;
  height: 33px;
  align-self: center;
  background: #f5c310;
  border: none;
  color: white;
  border-radius: 10px;
  margin-top: 62px;
  margin-bottom: 41px;
}

.full_part {
  display: flex;
  margin-top: 30px;
}

.pulse-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.point_detail {
  padding: 30px 62px 30px 62px;
}

.point_table_title {
  font-size: 14px;
  width: 100px;
  align-self: center;
}

.point_table_No {
  font-size: 14px;
  width: 50px;
  align-self: center;
}

.point_table_item {
  font-size: 14px;
}

.point_table_item_reason {
  width: 200px;
  font-size: 14px;
}

.point_title {
  font-weight: bold;
  width: 850px;
  color: white;
  background: gray;
  margin-bottom: 30px;
}

.point_detail_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.point_detail_data table {
  height: 80px;
  border-collapse: collapse;
}

.point_detail_data td {
  border-top: 1px solid #eee;
}
</style>
