import AsideVue from '@/components/Aside.vue';
<template>
  <div class="top-screen">
    <AsideVue screen_name="Training" @screen_reset="handleResetScreen" />
    <div class="home">

      <div v-if="isShowEDD">
        <AllTrainingData />
      </div>
    </div>
  </div>

</template>

<script>
import AsideVue from "../components/Aside.vue";
import "@material/web/checkbox/checkbox.js";
import AllTrainingData from "@/components/training_comp/AllTrainingData.vue";
import getDefaultDatas from "../components/composables/getDefaultDatas";
import { onMounted, ref, watch } from "vue";
import getPicDatas from "@/components/composables/getPicDatas";


export default {
  name: "Pay",
  components: {
    AsideVue,
    AllTrainingData,
  },
  data() {
    return {

      isShowConfirmEngineer: false,
      isShowEDD: true,
      is_onEdit: false,
      is_onDetail: false,
      is_onDelete: false,
      isSelect: false,
      edit_button_text: "編集",
      delete_button_text: "削除",
      detail_button_text: "詳細",
      defaultdata: null,
      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,
      isDetailItem: false,
      isShowButtonBox: true,
      isFix: false,
      on_edit_name: null,
      pageNo: 0,
      is_upload_button: true,
    };
  },
  setup() {
    const search_item = ref("");
    const fixted_search_item = ref("");
    const searchedDatas = ref([]);
    const local_pic_filter_data = ref([]);
    const inputPdfFile = ref("")

    const file = ref(null);
    const fileError = ref(null);

    const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("payData");

    onMounted(async () => {
      await datas_load();
      const pic_filter_data = await getPicDatas(defaultdatas.value);
      local_pic_filter_data.value = pic_filter_data.value;
      searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
    });




    const handleChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        file.value = selectedFile;
      } else {
        file.value = null;
      }
    };


    const handlePayDataUp = () => {


      try {
        uploadData(file.value.name, file.value)
      } catch (error) {

        console.error("ファイルのアップロード中にエラーが発生しました。", error);
        fileError.value = "ファイルのアップロード中にエラーが発生しました。";
      }


    }

    watch(search_item, async () => {
      if (local_pic_filter_data.value) {
        if (search_item.value) {
          fixted_search_item.value = search_item.value.toLowerCase();
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.filter((data) => {
            const fieldsToSearch = [
              "last_name",
              "first_name",
              "last_name_kana",
              "first_name_kana",
              "company_id",
            ];

            return fieldsToSearch.some((field) => {
              return (
                data[field] &&
                data[field].toLowerCase().includes(fixted_search_item.value)
              );
            });
          });
        } else {
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
        }
      }
      console.log("watch function");
    });

    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        })
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };


    return {
      defaultdatas,
      datas_error,
      search_item,
      searchedDatas,
      inputPdfFile,
      handleFilterArea,
      handleFilterPIC,

      handlePayDataUp,
      handleChange
    };
  }
}

</script>

<style>
.top-screen {
  display: flex;
}

.home {
  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}

.edit_button {
  width: 139px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.on_edit_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload_buttons {
  width: 900px;
  padding: 20px;
}
</style>
