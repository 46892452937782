<template>

  <div class="data_title_box">
    <div class="tab">
      <h2>各種アンケート</h2>
    </div>
  </div>
  <div class="data_wrapper" v-if="isPageState === 'entry'">
    <div class="data_innerbox">
      <h3>【実施中のアンケート】</h3>
      <div v-for="(data, index) in surveyData" :key="index">
        <div class="survey_title_box" @click="handleClickTitle(index)">
          <div>
            <h5>No.{{ index + 1 }}</h5>
            <h3>{{ data.survey_title }}</h3>
          </div>
          <h4>{{ formatedDate(data.survey_start) }}〜{{ formatedDate(data.survey_end) }}</h4>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import { ref, onMounted, watch } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import getDefaultDatas from "../composables/getDefaultDatas";

import { projectFirestore } from "@/firebase/config";


export default {
  props: {
    isSelect: Boolean,
    defaultdatas: {
      type: Array,
      default: () => [],
    },
    isFix: Boolean,
    reloadDefaultData: Function,
    now_page: Number,
  },
  name: "EngineerDefaultData",
  components: {

    AreaStatus,
    PICStatus,

  },
  data() {
    return {
      itemsPerPage: 6,

      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
    };
  },

  setup(props, context) {

    const formatedDate = (timestamp) => {

      if (!timestamp) return "";

      return timestamp.toDate().toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

        hour12: false
      }).replace(/\//g, '/');
    }
    const formatTime = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}時間${minutes}分`;
    };
    const currentPage = ref(1);
    const usersPayDatas = ref([]);

    const fetchedDatas = ref([]);
    const { defaultdatas, datas_load } = getDefaultDatas("engineers");
    const surveyData = ref([]);

    const fetchAllSurveyDatas = async () => {
      try {


        const res = await projectFirestore.collection("survey_data").get();
        surveyData.value = res.docs.map(doc => ({
          id: doc.id,       // ドキュメントID
          ...doc.data()     // ドキュメントの内容
        }));


        console.log("survey_data", surveyData.value)




        fetchedDatas.value = result.map((item) => ({
          ...item,
          first_name: userMap[item.user_id]?.first_name || "",
          last_name: userMap[item.user_id]?.last_name || "",
          pic: userMap[item.user_id]?.pic || "",
          status: userMap[item.user_id]?.status || "",
          area: userMap[item.user_id]?.area || "",
        }));


      } catch (error) {
        console.error(error);
      }
    };

    const emit = context.emit;
    const handleClickTitle = (index) => {
      const selectedData = surveyData.value.find(
        (item) => item.survey_no === index + 1
      );
      console.log("selectedData", selectedData)
      if (selectedData) {
        emit("select_data", index + 1);
      }
    };

    const changePage = (pageNumber) => {
      currentPage.value = pageNumber;
    };
    console.log("props.page", props.now_page);
    if (props.now_page === 0) {
      changePage(1);
    } else {
      changePage(props.now_page);
    }
    if (props.isFix) {
      console.log(props.defaultdatas);
      watch(
        () => props.defaultdatas.value,
        (newDefaultDatas) => {
          if (newDefaultDatas !== null) {
            props.defaultdatas.value = newDefaultDatas;
          }
        }
      );
    }

    const isPageState = ref("entry");
    const handlePageState = (state) => {
      if (state === "entry") {
        isPageState.value = "entry"
      } else if (state === "graph") {
        isPageState.value = "graph"
      } else if (state === "survey") {
        isPageState.value = "survey"
      } else {
        isPageState.value = "entry"
      }
      console.log("state", isPageState.value)

    };


    onMounted(async () => {
      await fetchAllSurveyDatas();
    });
    return {
      currentPage,
      changePage,
      usersPayDatas,
      fetchAllSurveyDatas,
      formatTime,

      handleClickTitle,
      isPageState,
      handlePageState,
      surveyData,
      formatedDate

    };
  },

  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    engineerCheck(id) {
      if (this.selectedItems.length === 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.shift();
        this.selectedItems.push(id);
      }
      this.currentPageItems.forEach((defaultdata) => {
        if (!this.selectedItems.includes(defaultdata.id)) {
          defaultdata.isSelected = false;
        }
      });

      console.log(this.selectedItems);
      this.$emit(
        "selected_engineer_id",
        this.selectedItems[0],
        this.currentPage
      );
    },
    handleFilter() {
      this.isFilter = !this.isFilter;
    },
    handleAreaChange() {
      this.$emit("area_selected", this.area_selected);
    },
    handlePICChange() {
      this.$emit("pic_selected", this.pic_selected);
    },
  },
};
</script>

<style scoped>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.survey_title_box {
  width: 650px;
  height: 80px;
  background-color: #fcc328;
  border-radius: 20px;
  border: 2px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}

.survey_title_box:hover {
  cursor: pointer;
  background-color: #ddd;
  color: #fcc328;
  border: 2px solid #fcc328;
}

.survey_title_box h5,
h3 {
  margin: 0px;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.data_whitebox {
  width: 800px;
  height: 800px;
  border-radius: 16px;
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.data_wrapper {
  width: 100%;
  height: auto;

  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.data_innerbox {
  width: auto;
}

.table_no {
  text-align: center;
}

.user_name {
  width: 180px;
  white-space: nowrap;
}

.user_name:hover {
  font-weight: bold;
  cursor: pointer;
}

.survey_title {
  width: 300px;
}

.survey_title:hover {
  font-weight: bold;
  cursor: pointer;
}

.data_innerbox th {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  border: 1px solid black;
}

.data_innerbox td {
  border: 1px solid black;
}



table {
  /* will-change: 100%; */
  border-collapse: collapse;
}

.comment_frame {
  width: 250px;
  height: 60px;
  overflow-y: auto;
  margin-left: -20px;
  font-size: 12px;

}
</style>
