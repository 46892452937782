import AsideVue from '@/components/Aside.vue';
<template>
  <div class="top-screen">
    <AsideVue screen_name="労働代表選挙" @screen_reset="handleResetScreen" />
    <div class="home">



      <div v-if="isShowEDD">
        <ElectionGraph></ElectionGraph>
        <AllElectionData :isSelect="isSelect" :defaultdatas="searchedDatas" :isFix="isFix" :now_page="pageNo"
          @selected_engineer_id="handleChildData" @area_selected="handleFilterArea" @pic_selected="handleFilterPIC" />
      </div>
    </div>



  </div>

</template>

<script>
import AsideVue from "../components/Aside.vue";
import "@material/web/checkbox/checkbox.js";
import AllElectionData from "@/components/election_comp/AllElectionData.vue";

import getDefaultDatas from "../components/composables/getDefaultDatas";


import { onMounted, ref, watch } from "vue";
import getPicDatas from "@/components/composables/getPicDatas";
import uploadPDF from "@/components/composables/uploadPDF";
import ElectionGraph from "@/components/election_comp/ElectionGraph.vue";

export default {
  name: "Pay",
  components: {
    AsideVue,
    AllElectionData,
    ElectionGraph,
  },
  data() {
    return {
      isShowAddEngineer: false,
      isShowConfirmEngineer: false,
      isShowEDD: true,
      is_onEdit: false,
      is_onDetail: false,
      is_onDelete: false,
      isSelect: false,
      edit_button_text: "編集",
      delete_button_text: "削除",
      detail_button_text: "詳細",
      defaultdata: null,
      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,
      isDetailItem: false,
      isShowButtonBox: true,
      isFix: false,
      on_edit_name: null,
      pageNo: 0,
      is_upload_button: true,
    };
  },
  setup() {
    const search_item = ref("");
    const fixted_search_item = ref("");
    const searchedDatas = ref([]);
    const local_pic_filter_data = ref([]);
    const inputPdfFile = ref("")

    const file = ref(null);
    const fileError = ref(null);

    const { defaultdatas, datas_error, datas_load } =
      getDefaultDatas("payData");

    onMounted(async () => {
      await datas_load();
      const pic_filter_data = await getPicDatas(defaultdatas.value);
      local_pic_filter_data.value = pic_filter_data.value;
      searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
        return a.enter_date - b.enter_date;
      });
    });

    const { createFolder, uploadData } = uploadPDF("pay_data")
    const handlecreatePayFolder = () => {
      const members = []
      for (const mem of defaultdatas.value) {
        //members.push(mem.id)
        createFolder(mem.id)
        //createMonthlyFolder(mem.id)
      }
      console.log("mem:", members)


    }

    const handleChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        file.value = selectedFile;
      } else {
        file.value = null;
      }
    };


    const handlePayDataUp = () => {


      try {
        uploadData(file.value.name, file.value)
      } catch (error) {

        console.error("ファイルのアップロード中にエラーが発生しました。", error);
        fileError.value = "ファイルのアップロード中にエラーが発生しました。";
      }


    }

    watch(search_item, async () => {
      if (local_pic_filter_data.value) {
        if (search_item.value) {
          fixted_search_item.value = search_item.value.toLowerCase();
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.filter((data) => {
            const fieldsToSearch = [
              "last_name",
              "first_name",
              "last_name_kana",
              "first_name_kana",
              "company_id",
            ];

            return fieldsToSearch.some((field) => {
              return (
                data[field] &&
                data[field].toLowerCase().includes(fixted_search_item.value)
              );
            });
          });
        } else {
          const pic_filter_data = await getPicDatas(defaultdatas.value);
          local_pic_filter_data.value = pic_filter_data.value;
          searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
            return a.enter_date - b.enter_date;
          });
        }
      }
      console.log("watch function");
    });

    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        })
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };


    return {
      defaultdatas,
      datas_error,
      search_item,
      searchedDatas,
      inputPdfFile,
      handleFilterArea,
      handleFilterPIC,
      handlecreatePayFolder,
      handlePayDataUp,
      handleChange
    };
  },
  computed: {},
  methods: {
    showAddEngineer() {
      this.isShowAddEngineer = !this.isShowAddEngineer;
      this.isShowEDD = !this.isShowEDD;
      this.is_upload_button = !this.is_upload_button;
    },
    selectEdit(receiveId) {
      this.isSelect = !this.isSelect;
      this.on_edit_name = "edit";
      this.is_onEdit = true;
      if (this.on_edit_name === "edit" && this.isSelect) {
        this.edit_button_text = "このユーザーを編集する";
        this.isShowDetail = false;
        this.isShowDelete = false;
      } else {
        if (receiveId !== null) {
          this.isShowFix = !this.isShowFix;
          this.edit_button_text = "編集";
          this.isShowEDD = !this.isShowEDD;
          this.is_upload_button = !this.is_upload_button;
          this.isShowButtonBox = !this.isShowButtonBox;
        } else {
          this.edit_button_text = "編集";
          this.on_edit_name = null;
          this.is_onEdit = false;
        }
      }
    },
    selectDetail(receiveId) {
      this.on_edit_name = "detail";
      this.is_onDetail = true;
      this.isSelect = !this.isSelect;
      if (this.on_edit_name === "detail" && this.isSelect) {
        this.detail_button_text = "このユーザーの詳細を見る";
      } else {
        if (receiveId !== null) {
          this.isShowDetail = !this.isSHowDetail;
          this.isShowEDD = !this.isShowEDD;
          this.is_upload_button = !this.is_upload_button;
          this.isShowButtonBox = !this.isShowButtonBox;
          this.detail_button_text = "詳細";
        } else {
          this.detail_button_text = "詳細";
          this.is_onDetail = false;
        }
      }
    },
    disableOtherButton() {
      if (this.isSelect) {
        if (isDetailItem) {
        }
      }
    },
    selectDelete(receiveId) {
      this.on_edit_name = "delete";
      this.is_onDelete = true;
      this.isSelect = !this.isSelect;

      if (this.on_edit_name === "delete" && this.isSelect) {
        this.delete_button_text = "このユーザーを削除する";
      } else {
        if (receiveId !== null) {
          this.isShowDelete = true;
          this.delete_button_text = "削除";
        } else {
          this.delete_button_text = "削除";
          this.is_onDelete = false;
        }
      }
    },
    handleChildData(data_id, page_no) {
      this.receiveId = data_id;
      this.isShowDetail = !this.isSHowDetail;
      this.isShowEDD = !this.isShowEDD;
      this.is_upload_button = !this.is_upload_button;
      this.isShowButtonBox = !this.isShowButtonBox;
      this.pageNo = page_no;
      console.log("pageNO:", page_no);
    },
    handleReturnButtonClick(is_close) {
      this.isShowEDD = is_close;
      this.is_upload_button = is_close;
      this.isShowDetail = !is_close;
    },
    handlePushEditButton(is_push) {
      this.isShowDetail = !is_push;
      this.isShowFix = is_push;
      this.isShowButtonBox = false;
    },
    handlePushDescription(is_push) {
      this.isShowDetail = is_push;
      this.isShowFix = !is_push;
    },
    handleFixClose(is_close) {
      this.isShowFix = !is_close;
      this.isShowEDD = is_close;
      this.is_upload_button = is_close;
      this.isShowButtonBox = true;
      this.isFix = true;
      this.reloadDefaultData();
    },
    handleResetScreen() {
      this.isShowAddEngineer = false;
      this.isShowButtonBox = true;
      this.isShowEDD = true;
      this.is_upload_button = true;
      this.isReturnButton = false;
      this.isShowDetail = false;
    },
  },
};
</script>

<style>
.top-screen {
  display: flex;
}

.home {
  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}

.edit_button {
  width: 139px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.on_edit_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload_buttons {
  width: 900px;
  padding: 20px;
}
</style>
