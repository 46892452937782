<template>
  <div class="data_view_box">
    <div class="list_tag_frame">
      <div @click="handleChangeVoteList" class="mvp_result_title">
        <h1>がんばったMVP2024アンケート結果</h1>
      </div>

      <div @click="handleChangeNotVoteList" class="not_vote_tag">
        <h3>投票していない方一覧</h3>
      </div>
    </div>
    <div class="survey_graphs" v-if="!isChangeList">
      <div class="graph_box">
        <h3>プライベートの部の賞金額</h3>
        <MvpVoteGraph :voteDatas="serveyDataQ1"></MvpVoteGraph>
      </div>
      <div class="graph_box">
        <h3>仕事の部の賞金額</h3>
        <MvpVoteGraph :voteDatas="serveyDataQ2"></MvpVoteGraph>
      </div>
      <div class="graph_box">
        <h3>枠増加の感想</h3>
        <MvpVoteGraph :voteDatas="serveyDataQ3"></MvpVoteGraph>
      </div>

    </div>

    <div class="mvp_survey_table" v-if="!isChangeList">
      <h3>投票者とアンケート回答</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>お名前</th>
            <th>エリア</th>
            <th>Q5.プライベートの部の賞金額（3万円）はどう思いますか。</th>

            <th>
              Q5-2.プライベートの部の賞金額がいくらなら応募したくなりますか。
            </th>

            <th>Q6.仕事の部の賞金額（5万円）はどう思いますか。</th>

            <th>Q6-2.仕事の部の賞金額がいくらなら応募したくなりますか</th>

            <th>Q7.枠の設定はどうでしたか。</th>
            <th>
              Q8.今回のがんばったMVP2024はどうでしたか。率直な感想をお書きください。
            </th>
            <th>
              Q9.今後の頑張ったMVPは、どうしたらもっと楽しめると思いますか。あなたの意見をお聞かせください。
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in fechedMvpAllServeyDatas" :key="item.id">
            <td>
              <div class="survey_no">{{ index + 1 }}</div>
            </td>
            <td>
              <div class="survey_name">{{ item.last_name }} {{ item.first_name }}</div>
            </td>
            <td>
              <div class="area">
                <AreaStatus :id="item.userId" :area="item.area" />
              </div>
            </td>
            <td>
              <div class="survey_q1"> {{ item.servey_q1 }}</div>

            </td>
            <td v-if="item.servey_q1_sub !== 0">{{ item.servey_q1_sub }}万円</td>
            <td v-else>
              <div class="survey_center">-</div>
            </td>
            <td>
              <div class="survey_q2">
                {{ item.servey_q2 }}
              </div>
            </td>
            <td v-if="item.servey_q2_sub !== 0">{{ item.servey_q2_sub }}万円</td>
            <td v-else>
              <div class="survey_center">-</div>
            </td>
            <td>
              <div class="survey_q3">
                {{ item.servey_q3 }}
              </div>
            </td>
            <td>
              <div class="survey_q4">
                {{ item.servey_q4 }}
              </div>
            </td>
            <td>
              <div class="survey_q5">
                {{ item.servey_q5 }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isChangeList" class="no_vote_list">
      <h3>投票しなかったメンバー</h3>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>
              <div class="survey_name">お名前</div>
            </th>
            <th>エリア</th>
            <th>担当</th>
            <th>備考</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(member, index) in fetchedNotVoteMember" :key="member">
            <td>
              <div class="survey_no">{{ index + 1 }}</div>
            </td>
            <td>
              <div class="survey_name">{{ member.last_name }}{{ member.first_name }}</div>
            </td>
            <td>
              <AreaStatus :id="member.company_id" :area="member.area" />
            </td>
            <td>
              <PICStatus :id="member.company_id" :pic="member.pic" />
            </td>
            <td>
              <div v-if="!inputAreaArray[index].is_open">{{ reasonArray[index] }}</div>
              <div v-else><input type="text" v-model="reasonArray[index]" class="not_reason_input_area"><button
                  @click="updateMvpHistories(index, member.company_id, 2024, reasonArray[index])">登録</button></div>
            </td>
            <td><button @click="handleOpenTexArea(index)">{{ inputAreaArray[index].input_btn_name }}</button></td>

          </tr>
        </tbody>
      </table>
    </div>


  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import MvpVoteGraph from "./MvpVoteGraph.vue";
import { projectFirestore } from "@/firebase/config";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";




export default {
  props: {
    youngEntry: {
      type: Array,
      required: true,
    },
    middleEntry: {
      type: Array,
      required: true,
    },
    beteranEntry: {
      type: Array,
      required: true,
    },
    privateEntry: {
      type: Array,
      required: true,
    },
  },
  components: {
    MvpVoteGraph, AreaStatus, PICStatus
  },

  setup(props) {
    const fechedMvpAllServeyDatas = ref([]);

    const serveyDataQ1 = ref([]);
    const serveyDataQ2 = ref([]);
    const serveyDataQ3 = ref([]);


    const notVoteReason = ref("");
    const reasonArray = ref([]);


    const fetchedNotVoteMember = ref([]);
    const isChangeList = ref(false);
    const handleChangeVoteList = () => {
      isChangeList.value = false;
    }
    const handleChangeNotVoteList = () => {
      isChangeList.value = true;
    }

    const inputAreaArray = ref([]);

    const fetchMvpVoteData = async () => {
      try {

        const member_res = await projectFirestore.collection("engineers").get();

        const memberData = member_res.docs.map(doc => doc.data()
        );


        const res = await projectFirestore
          .collection("mvp_vote_result")
          .get();
        fechedMvpAllServeyDatas.value = res.docs.map((doc) => {
          const surveyData = doc.data();

          const matchingMember = memberData.find(
            (member) => member.company_id === surveyData.userId
          )
          if (matchingMember) {
            surveyData.first_name = matchingMember.first_name || "";
            surveyData.last_name = matchingMember.last_name || "";
            surveyData.area = matchingMember.area || "";
          }
          return surveyData;
        }
        );

        const surveyUserIds = res.docs.map(doc => doc.data().userId)

        fetchedNotVoteMember.value = memberData.filter(member => !surveyUserIds.includes(member.company_id));

        inputAreaArray.value = Array.from({ length: fetchedNotVoteMember.value.length }, () => ({
          is_open: false,
          input_btn_name: "備考入力"
        }));
        reasonArray.value = fetchedNotVoteMember.value.map(member => {
          if (member.mvp_history) {
            const historyFor2024 = member.mvp_history.filter(history => history.vote_year === 2024);

            if (historyFor2024.length > 0) {
              return historyFor2024[0].not_vote_reason || ""
            }
          }
          return "";

        }
        )

        console.log("reasonArray", reasonArray.value)

        const q1ResultMap = fechedMvpAllServeyDatas.value.reduce(
          (acc, entry) => {
            let title;
            if (entry.servey_q1 === "") {
              title = "未回答"
            } else {
              title = entry.servey_q1
            }
            if (acc[title]) {
              acc[title].count += 1;
            } else {
              acc[title] = { title, count: 1 };
            }
            return acc;
          },
          {}
        );
        serveyDataQ1.value = Object.values(q1ResultMap);

        const q2ResultMap = fechedMvpAllServeyDatas.value.reduce(
          (acc, entry) => {
            let title;
            if (entry.servey_q2 === "") {
              title = "未回答"
            } else {
              title = entry.servey_q2
            }

            if (acc[title]) {
              acc[title].count += 1;
            } else {
              acc[title] = { title, count: 1 };
            }
            return acc;
          },
          {}
        );
        serveyDataQ2.value = Object.values(q2ResultMap);


        const q3ResultMap = fechedMvpAllServeyDatas.value.reduce(
          (acc, entry) => {

            let title;
            if (entry.servey_q3 === "") {
              title = "未回答"
            } else {
              title = entry.servey_q3;
            }
            if (acc[title]) {
              acc[title].count += 1;
            } else {
              acc[title] = { title, count: 1 };
            }
            return acc;
          },
          {}
        );
        serveyDataQ3.value = Object.values(q3ResultMap);


      } catch (error) {
        console.error(error.message);
      }
    };

    const updateMvpHistories = async (index, companyId, voteYear, Reason) => {


      try {

        const newMvpHistory = {
          vote_year: voteYear,
          is_entry: null,
          is_vote: false,
          not_vote_reason: Reason

        }
        const querySnapshot = await projectFirestore.collection("engineers")
          .where("company_id", "==", companyId)
          .get();



        if (querySnapshot.empty) {
          console.log(`対象のエンジニア（company_id:${companyId}は見つかりませんでした。`)
        }
        const doc = querySnapshot.docs.find(() => true);

        if (!doc) {
          console.log(`対象のエンジニア (company_id: ${companyId}) は見つかりませんでした。`);
          return;
        }

        const docRef = projectFirestore.collection("engineers").doc(doc.id);
        const currentData = doc.data();

        const updatedHistory = currentData.mvp_history
          ? currentData.mvp_history.map(item => {
            if (item.vote_year === newMvpHistory.vote_year) {
              return newMvpHistory;
            }
            return item;
          })
          : [newMvpHistory];

        if (!updatedHistory.some(item => item.vote_year === newMvpHistory.vote_year)) {
          updatedHistory.push(newMvpHistory)
        }

        await docRef.update({ mvp_history: updatedHistory })
        console.log(`${companyId}のデータをアップデートしました。`);
        closeTextArea(index);


      } catch (error) {
        console.error("mvp_history の更新に失敗しました:", error);
      }
    }
    const input_btn_name = ref("備考入力")
    const handleOpenTexArea = (index) => {
      inputAreaArray.value[index].is_open = !inputAreaArray.value[index].is_open;
      if (!inputAreaArray.value[index].is_open) {
        inputAreaArray.value[index].input_btn_name = "備考入力"
      } else {
        inputAreaArray.value[index].input_btn_name = "キャンセル"
      }
    }

    const closeTextArea = (index) => {
      inputAreaArray.value[index].is_open = false;
      inputAreaArray.value[index].input_btn_name = "備考入力"
    }


    onMounted(async () => {
      await fetchMvpVoteData();
    });

    return {
      fechedMvpAllServeyDatas,
      serveyDataQ1,
      serveyDataQ2,
      serveyDataQ3,
      fetchedNotVoteMember,
      isChangeList,
      handleChangeVoteList,
      handleChangeNotVoteList,
      updateMvpHistories,
      notVoteReason,
      reasonArray,
      inputAreaArray,
      handleOpenTexArea,
      input_btn_name

    };
  },
};
</script>
<style scoped>
.data_view_box {
  width: 1200px;
  height: auto;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}

.data_flex {
  width: 100%;
  height: auto;
  display: flex;
  gap: 20px;
}

.data_item {
  width: 450px;
  height: 400px;
  border: 4px solid #ddd;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.mvp_survey_table {
  width: 1180px;
  height: auto;
  overflow-y: auto;
}

.mvp_survey_table table {
  width: 100%;
  height: 800px;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.mvp_survey_table th {
  border: 1px solid #ddd;

}

.mvp_survey_table td {
  border: 1px solid #ddd;
}

.survey_no {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey_name {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey_q1 {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.survey_graphs {
  width: 1000px;
  height: auto;
  display: flex;
  gap: 20px;
}

.graph_box {
  width: 300px;
  height: 420px;
  border: 2px solid #ddd;
  border-radius: 20px;
  padding: 10px;
}

.list_tag_frame {
  width: 800px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no_vote_list {
  width: 600px;
}

.no_vote_list table,
th,
td {
  border: 1px solid #ddd;
  border-collapse: collapse;

}

.not_reason_input_area {
  width: 200px;
  height: 30px;

}

.not_vote_tag:hover {
  cursor: pointer;
  color: #ddd;
}

.mvp_result_title:hover {
  cursor: pointer;
  color: #ddd;
}
</style>
