<template>
  <div class="point_all_data">
    <h3>社内貢献ポイント エンジニア一覧</h3>
    <div>
      <table>
        <tr>
          <th>#</th>
          <th>氏名</th>
          <th>エリア</th>
          <th>担当者</th>
          <th>保有ポイント</th>
          <th>最大換金額</th>
          <th>利用申請</th>
        </tr>

        <tr v-for="(data, index) in datas" :key="data.id">
          <td class="table_item_number">{{ (index + 1).toString().padStart(2, "0") }}</td>
          <td class="table_item_long">{{ data.last_name }}{{ data.first_name }}</td>
          <td class="table_item_short" v-if="data.area">
            <AreaStatus :id="data.id" :area="data.area" />
          </td>
          <td class="table_item_short" v-if="data.area">
            <PICStatus :id="data.id" :pic="data.pic" />
          </td>

          <td class="table_item_short">{{ data.hold_point }}pt</td>
          <td class="table_item_short">{{ data.can_change_money }}円</td>
          <td class="table_item_short">
            <DefaultStatus :is_submit="data.is_submit" />
          </td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import { onMounted } from 'vue';
import AreaStatus from '../home_comp/AreaStatus.vue';
import PICStatus from '../home_comp/PICStatus.vue';
import DefaultStatus from './DefaultStatus.vue';


export default {
  props: ["datas"],
  setup(props, context) {


  },
  components: { AreaStatus, PICStatus, DefaultStatus }
}
</script>

<style>
.point_all_data {
  width: 1000px;
  height: auto;
  border-radius: 16px;
  background: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.point_all_data table {
  font-size: 15px;
  border-collapse: collapse;
}

.point_all_data th {
  font-size: 13.5px;
}


.point_all_data td {
  height: 50px;
  border-top: 1px solid #ddd;
}

.table_item_number {
  width: 80px;
  text-align: center;
}

.table_item_short {
  width: 100px;
  text-align: center;
}

.table_item_long {
  width: 130px;
}
</style>