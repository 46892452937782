import AsideVue from '@/components/Aside.vue';
<template>
  <div class="top-screen">
    <AsideVue screen_name="各種アンケート" />
    <div class="survey_screen">



      <div v-if="isShowEDD">
        <div class="titles">
          <AllServeyTitles :now_page="pageNo" @select_data="fetchSelectedSurveyDatas" />
        </div>
      </div>

      <div v-if="selectedSurveyData">
        <div class="white_block" v-if="isDetailAnswer">
          <div class="close_btn" @click="handleCloseDetail">×</div>
          <h2>{{ selectedSurveyData[0].survey_title }}</h2>

          <h4>【実施期間】</h4>
          <p>{{ formatedDate(selectedSurveyData[0].survey_start) }}〜{{ formatedDate(selectedSurveyData[0].survey_end) }}
          </p>



          <h4>アンケート回答一覧</h4>
          <div class="table_wrapper">
            <table>
              <thead>
                <tr class="column_title_tr">
                  <th>
                    No.
                  </th>
                  <th>
                    お名前
                  </th>
                  <th>
                    エリア
                  </th>
                  <th>
                    {{ selectedSurveyData[0].survey_questions[0] }}
                  </th>
                  <th>
                    {{ selectedSurveyData[0].survey_questions[1] }}
                  </th>
                  <th>
                    {{ selectedSurveyData[0].survey_questions[2] }}
                  </th>
                  <th>
                    {{ selectedSurveyData[0].survey_questions[3] }}
                  </th>
                  <th>送信日時</th>

                  <th>いいね</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(answer, index) in userAnswers" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ answer.last_name }} {{ answer.first_name }}</td>
                  <td>
                    <AreaStatus :id="answer.userId" :area="answer.area"></AreaStatus>
                  </td>
                  <td>{{ answer.idea }}</td>
                  <td>{{ answer.reason }}</td>
                  <td>{{ answer.money }}円くらい</td>
                  <td>{{ answer.opinion }}</td>
                  <td>{{ formatedDate(answer.date) }}</td>

                  <td>{{ answer.iine_members.length }}</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>


    </div>



  </div>

</template>

<script>
import AsideVue from "../components/Aside.vue";
import "@material/web/checkbox/checkbox.js";

import AllServeyTitles from "@/components/survey_comp/AllServeyTitles.vue";
import { ref } from "vue";
import { projectFirestore } from "@/firebase/config";
import getDefaultDatas from "@/components/composables/getDefaultDatas";
import AreaStatus from "@/components/home_comp/AreaStatus.vue";

export default {
  name: "Survey",
  components: {
    AsideVue,
    AllServeyTitles,
    AreaStatus
  },
  data() {
    return {










      receiveId: null,
      isShowDetail: false,
      isShowDelete: false,
      isShowFix: false,

      isShowButtonBox: true,
      isFix: false,
      on_edit_name: null,
      pageNo: 0,
      is_upload_button: true,
      personal_entry_data: {},
      isDetail: false,
    };
  },
  setup() {
    const isShowEDD = ref(true);
    const isDetailAnswer = ref(false);
    const handleCloseDetail = () => {
      isShowEDD.value = true;
      isDetailAnswer.value = false;
    }

    const handleOpenDetail = () => {
      isShowEDD.value = false;
      isDetailAnswer.value = true;
    }

    const formatedDate = (timestamp) => {

      if (!timestamp) return "";

      return timestamp.toDate().toLocaleString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',

        hour12: false
      }).replace(/\//g, '/');
    }

    const { defaultdatas, datas_load } = getDefaultDatas("engineers");
    const userAnswers = ref([]);


    const selectedSurveyData = ref(null);
    const fetchSelectedSurveyDatas = async (select_no) => {

      try {
        const res = await projectFirestore
          .collection("survey_data")
          .where("survey_no", "==", select_no)
          .get();

        console.log("no", select_no)

        selectedSurveyData.value = res.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        await datas_load();

        const answers = selectedSurveyData.value[0].answers;
        userAnswers.value = answers.map(item => {
          const matchData = defaultdatas.value.find(data => data.company_id === item.userId)
          return {
            ...item,
            first_name: matchData ? matchData.first_name : null,
            last_name: matchData ? matchData.last_name : null,
            area: matchData ? matchData.area : null,
          }
        })
        handleOpenDetail();
        console.log("userAnswers", userAnswers.value)
      } catch (error) {
        console.error("error:", error.message)
      }
    }
    const handleFilterArea = (area_selected) => {
      console.log("area_selected:", area_selected);
      if (area_selected !== null && area_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.area === area_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        })
      }
    };

    const handleFilterPIC = (pic_selected) => {
      if (pic_selected !== null && pic_selected !== "all") {
        searchedDatas.value = local_pic_filter_data.value.filter((data) => {
          return data.pic === pic_selected;
        }).sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      } else {
        searchedDatas.value = local_pic_filter_data.value.sort((a, b) => {
          return a.enter_date - b.enter_date;
        });
      }
    };


    return {
      isShowEDD,
      isDetailAnswer,
      handleFilterArea,
      handleFilterPIC,
      selectedSurveyData,
      fetchSelectedSurveyDatas,
      formatedDate,
      userAnswers,
      handleCloseDetail
    };
  },
  methods: {






  },
};
</script>

<style scoped>
.titles {
  width: auto;
  margin-left: 50px;
  height: 900px;
}



.white_block {
  position: relative;
  width: 1000px;
  height: 800px;
  border-radius: 16px;
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.white_block h4 {
  margin: 0;
}

.table_wrapper {
  width: 100%;
  height: auto;
  overflow-y: auto;

}

.white_block table,

td {
  font-size: 15px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.white_block th {
  font-size: 15px;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  vertical-align: center;
}

.column_title_tr {
  background-color: #fcc328;
  vertical-align: center;


}


.column_no {
  width: 50px;
  height: 100px;
  background-color: #fcc328;
  display: grid;
  place-items: center;

}


.column_name {
  width: 100px;
  height: 100px;
  background-color: #fcc328;
  display: flex;
  align-items: center;
  justify-content: center;
}










.close_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 25px;
  font-weight: bold;
}

.close_btn:hover {
  color: #ddd;
  cursor: pointer;
}

.top-screen {
  width: 100vw;
  height: auto;
  display: flex;
}

.survey_screen {

  background: #f8ffe4;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.space_box {
  width: 200px;
  height: 800px;
}

.detail_content {
  margin-left: 30px;
}

.button_box {
  margin-top: 71px;
  margin-left: 20px;
  margin-bottom: 25px;
  display: flex;
}

.edit_button {
  width: 139px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.on_edit_button {
  width: 200px;
  height: 37px;
  background: rgba(245, 195, 16, 1);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload_buttons {
  width: 900px;
  padding: 20px;
}
</style>
