<template>
  <div class="data_whitebox">

    <div class="data_title_box">
      <h2>研修実施状況</h2>
    </div>

    <div class="select_flex">
      <div @click="handleChangeData(true)" :class="{ 'change_btn_on': isFuture, 'change_btn_off': !isFuture }">
        本日以降の予約分
      </div>
      <div @click="handleChangeData(false)" :class="{ 'change_btn_on': isPast, 'change_btn_off': !isPast }">
        過去の予約分
      </div>
    </div>

    <div class="data_wrapper" v-if="isFuture">
      <div class="data_innerbox">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>エリア</th>
              <th>担当者</th>
              <th>名前</th>
              <th>研修講座</th>
              <th>研修時間合計</th>
              <th>通信欄</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in sortedFutureTrainingDatas" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>

              <td class="lecture" style="overflow-x: auto;">
                <table>
                  <tr v-for="(lecture, index) in userdata.trainings" :key="index">
                    <td class="lecture_name">

                      <div><span>{{ lecture.lecture_name }}</span></div>
                      <div><span>【アカウント名】</span>{{ lecture.lecture_account }}</div>

                      <div v-for="(time, index) in lecture.lecture_times" :key=index class="lecture_time">
                        <span>【実施予定日】</span>
                        <div>{{ time.date }}</div>
                        <span>【予定開始時間】</span>
                        <div>{{ time.scheduled_time }}</div>
                        <span>【開始】</span>
                        <div v-if="time.start">{{ time.start }}</div>
                        <div v-else>未実施</div>
                        <span>【終了】</span>
                        <div v-if="time.end">{{ time.end }}</div>
                        <div v-else>未実施</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                {{ formatTime(userdata.total_time) }}
              </td>
              <td>
                <div class="message_field">
                  {{ userdata.user_message }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="data_wrapper" v-if="isPast">
      <div class="data_innerbox">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>エリア</th>
              <th>担当者</th>
              <th>名前</th>
              <th>研修講座</th>
              <th>研修時間合計</th>
              <th>通信欄</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(userdata, index) in sortedPastTrainingDatas" :key="index">
              <td class="table_no">{{ index + 1 }}</td>
              <td>
                <AreaStatus :id="userdata.user_id" :area="userdata.area" />
              </td>
              <td>
                <PICStatus :id="userdata.user_id" :pic="userdata.pic" />
              </td>
              <td class="user_name">
                <div>{{ userdata.last_name }}{{ userdata.first_name }}</div>
              </td>

              <td class="lecture" style="overflow-x: auto;">
                <table>
                  <tr v-for="(lecture, index) in userdata.trainings" :key="index">
                    <td class="lecture_name">

                      <div><span>{{ lecture.lecture_name }}</span></div>
                      <div><span>【アカウント名】</span>{{ lecture.lecture_account }}</div>

                      <div v-for="(time, index) in lecture.lecture_times" :key=index class="lecture_time">
                        <span>【実施予定日】</span>
                        <div>{{ time.date }}</div>
                        <span>【予定開始時間】</span>
                        <div>{{ time.scheduled_time }}</div>
                        <span>【開始】</span>
                        <div v-if="time.start">{{ time.start }}</div>
                        <div v-else>未実施</div>
                        <span>【終了】</span>
                        <div v-if="time.end">{{ time.end }}</div>
                        <div v-else>未実施</div>
                      </div>
                    </td>


                  </tr>
                </table>
              </td>
              <td>
                {{ formatTime(userdata.total_time) }}
              </td>
              <td>
                <div class="message_field">
                  {{ userdata.user_message }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>



  </div>
</template>

<script>
import DefaultStatus from "./DefaultStatus.vue";
import { ref, onMounted } from "vue";
import AreaStatus from "../home_comp/AreaStatus.vue";
import PICStatus from "../home_comp/PICStatus.vue";
import getDefaultDatas from "../composables/getDefaultDatas";
import { projectFirestore } from "@/firebase/config";

export default {

  name: "EngineerDefaultData",
  components: {
    DefaultStatus,
    AreaStatus,
    PICStatus,
  },
  data() {
    return {
      itemsPerPage: 6,
      item_num: 1,
      selectedItems: [],
      isFilter: false,
      area_selected: null,
      pic_selected: null,
    };
  },


  setup(props) {

    const formatTime = (totalMinutes) => {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}時間${minutes}分`
    }
    const currentPage = ref(1);
    const usersPayDatas = ref([]);
    const isFuture = ref(true);
    const isPast = ref(false);

    const handleChangeData = (bool) => {
      if (bool) {
        isFuture.value = true;
        isPast.value = false;
      } else {
        isFuture.value = false;
        isPast.value = true;
      }
    }

    const fetchedTrainingDatas = ref([]);
    const finishedTrainingDatas = ref([]);
    const sortedFutureTrainingDatas = ref([]);
    const sortedPastTrainingDatas = ref([]);

    const { defaultdatas, datas_load } = getDefaultDatas("engineers");

    const fetchTrainingDatas = async () => {
      try {
        await datas_load();

        const combinedLectures = {};

        const res = await projectFirestore.collection("selectedUdemys").get();
        res.docs.forEach((doc) => {
          const data = doc.data();
          const userId = data.userId
          if (combinedLectures[userId]) {
            combinedLectures[userId].trainings.push({
              lecture_name: data.name,
              lecture_account: data.account_name,
              lecture_date: data.date,
              lecture_times: [...data.learn_time],
            })

          } else {
            combinedLectures[userId] = {
              user_id: userId,
              trainings: [{
                lecture_name: data.name,
                lecture_account: data.account_name,
                lecture_date: data.date,
                lecture_times: [...data.learn_time]

              }]
            }
          }


        });
        const result = Object.values(combinedLectures);

        console.log("result", result)

        const userMap = {};
        defaultdatas.value.forEach(user => {
          userMap[user.company_id] = {
            first_name: user.first_name,
            last_name: user.last_name,
            pic: user.pic,
            status: user.status,
            area: user.area,
            total_time: user.training?.total_time || '',
            user_message: user.training?.message || ''
          }
        });

        const today = new Date();
        fetchedTrainingDatas.value = result
          .map(item => {
            const filteredTrainings = item.trainings
              .filter(training => {

                const hasUpcomingDate = training.lecture_date.some(dateObj => {
                  const lectureDate = new Date(dateObj.date);
                  return lectureDate >= today;
                });
                return hasUpcomingDate;
              });

            if (filteredTrainings.length === 0) {
              return null;
            }


            return {
              ...item,
              trainings: filteredTrainings,
              first_name: userMap[item.user_id]?.first_name || '',
              last_name: userMap[item.user_id]?.last_name || '',
              pic: userMap[item.user_id]?.pic || '',
              status: userMap[item.user_id]?.status || '',
              area: userMap[item.user_id]?.area || '',
              total_time: userMap[item.user_id]?.total_time || '',
              user_message: userMap[item.user_id]?.user_message || '',
            };

          })
          .filter(Boolean);

        sortedFutureTrainingDatas.value = fetchedTrainingDatas.value.sort((a, b) => {
          const nearestDateA =
            a.trainings
              .map(training => training.lecture_date?.find(dateObj => new Date(dateObj.date) >= today)?.date)
              .find(date => date !== undefined);

          const nearestDateB =
            b.trainings
              .map(training => training.lecture_date?.find(dateObj => new Date(dateObj.date) >= today)?.date)
              .find(date => date !== undefined);

          if (nearestDateA && nearestDateB) {
            return new Date(nearestDateA) - new Date(nearestDateB);
          }
          return 0;
        });


        finishedTrainingDatas.value = result
          .map(item => {
            const filteredTrainings = item.trainings.filter(training => {
              return training.lecture_date.every(dateObj => {
                const lectureDate = new Date(dateObj.date);
                return lectureDate < today;
              });
            });
            if (filteredTrainings.length === 0) {
              return null;
            }
            return {
              ...item,
              trainings: filteredTrainings,
              first_name: userMap[item.user_id]?.first_name || '',
              last_name: userMap[item.user_id]?.last_name || '',
              pic: userMap[item.user_id]?.pic || '',
              status: userMap[item.user_id]?.status || '',
              area: userMap[item.user_id]?.area || '',
              total_time: userMap[item.user_id]?.total_time || '',
              user_message: userMap[item.user_id]?.user_message || ''
            }

          })
          .filter(Boolean);

        sortedPastTrainingDatas.value = finishedTrainingDatas.value.sort((a, b) => {
          const nearestDateA =
            a.trainings
              .map(training => training.lecture_date?.find(dateObj => new Date(dateObj.date) >= today)?.date)
              .find(date => date !== undefined);

          const nearestDateB =
            b.trainings
              .map(training => training.lecture_date?.find(dateObj => new Date(dateObj.date) >= today)?.date)
              .find(date => date !== undefined);

          if (nearestDateA && nearestDateB) {
            return new Date(nearestDateA) - new Date(nearestDateB);
          }
          return 0;
        });


        console.log("finishedTrainingDatas", finishedTrainingDatas.value);
        console.log("sortedPastTrainingDatas", sortedPastTrainingDatas.value);

      } catch (error) {
        console.error(error)
      }
    }


    onMounted(async () => {
      await fetchTrainingDatas();
    });

    return {
      currentPage,
      usersPayDatas,
      fetchTrainingDatas,
      fetchedTrainingDatas,
      finishedTrainingDatas,
      formatTime,
      isFuture,
      isPast,
      handleChangeData,
      sortedFutureTrainingDatas,
      sortedPastTrainingDatas

    }


  },


};
</script>

<style scoped>
.data_title_box {
  width: 1042px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data_title_box button {
  width: 102px;
  height: 44px;
  border-radius: 12px;
  border: 1px solid #cdcde3;
  color: #a8a8bd;
  line-height: 19.6px;
  word-wrap: break-word;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter .material-symbols-outlined {
  color: #a8a8bd;
  font-size: 20px;
  margin-left: 5px;
}

.data_whitebox {
  width: 1135px;
  height: 900px;
  border-radius: 16px;
  background: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 20px;

}

.data_wrapper {
  width: 100%;
  overflow-x: auto;

}

.data_innerbox {
  width: auto;


}

.table_no {
  text-align: center;
}


.user_name {
  width: 180px;
  white-space: nowrap;
}

.lecture {
  width: 100%;
}

.lecture_name {
  width: 500px;
  height: auto;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  flex-direction: column;

}

.lecture_name div {
  display: inline-block;
}

.lecture_time {
  display: flex;
  width: 200px;
  font-size: 14px;
  align-items: center;
  gap: 10px;
}

.lecture_name span {
  font-weight: bold;
}

.lecture_time span {
  font-weight: bold;
}



table {
  /* will-change: 100%; */
  border-collapse: collapse;
}

.lecture table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}



.lecture_date {
  width: 100px;

}

.lecture td,
th {
  border: none;

}

th {
  border: 1px solid #ccc;
  padding: 8px;
  white-space: nowrap;
}

td {
  border: 1px solid #ccc;
  padding: 8px;
  overflow-wrap: anywhere;
  overflow-y: auto;
}

.message_field {
  width: 100px;
  font-size: 13px;

}

.select_flex {
  display: flex;
  gap: 10px;
}

.change_btn_on {
  width: 180px;
  height: 40px;
  background-color: #c1ff72;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: background-color 0.3s ease;
}

.change_btn_off {
  width: 180px;
  height: 40px;
  background-color: #ddd;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: background-color 0.3s ease;
}
</style>
